
import * as API_Floor from '@/api/floor';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {$goodsCategory} from '@/pages/goods/services/goods-category';
import {turnUrl} from '@/utils';

@Component({
  name: 'market-header',
})
export default class MarketHeader extends Vue {
  currentTab: any = "-1";
  searchContent = '';
  category = '';
  pathUrl: any = "";
  categoryProxy = $goodsCategory.create();
  navList: any[] = [];
  key_word = 'goods_name'
  pathList: any[] =['/markets/good','/shop-goods/good/1']

  @Prop({default: () => '/markets/privateGoods'}) tabBaseUrl!: string;
  @Prop({default: () => '/markets/market'}) homeHref!: string;
  @Prop({default: () => 'goods_name'}) search!: string;

  mounted() {
    this.pathUrl = this.$route.name
    console.log(this.pathUrl, 'this.pathUrl')
    API_Floor.getNav().then(res => {
      this.navList = res;
      // console.log(res)
      this.navList.unshift({
        navigation_name: '全部分类',
        navigation_id: "-1",
        url: "markets/privateGoods/0/0"
      })
      this.emitParams(0);
    });
  }

  @Watch('$route', {immediate: true})
  routeChange() {
    // console.log(parseInt(this.$route.params.nav))
    this.currentTab = parseInt(this.$route.params.nav) === 0 ? "-1" : `${parseInt(this.$route.params.nav)}`;
    this.category = this.$route.query.category_path as any;
    this.searchContent = this.$route.query.keyword as any || this.searchContent;
  }

  @Watch('search')
  searchChange() {
    this.key_word = this.search
  }

  getPath(item) {
    let path = item.url;
    console.log(path, this.$route.name, 'this.$route.name')
    if (path.indexOf('markets/privateGoods') >= 0) {
      const urlParams = turnUrl(item.url);
      if(this.$route.name === 'marketGooda') {
        this.tabBaseUrl = '/shop-goods/goods'
        // path = `/shop-goods/goods/${item.navigation_id}/${urlParams.self_goods}`;
        // this.$router.push('/shop-goods/goods/65/2')
      }
      path = `${this.tabBaseUrl}/${item.navigation_id}/${urlParams.self_goods}`;
    }
    return path;
  }

  selectChange() {
    // console.log(this.category)
    // if (!this.category) return;
    this.emitParams(2)
  }

  handleTabClick(item) {
    // console.log(item)
    if(this.currentTab === "-1") {
      this.selectChange()
      return
    }
    this.navList.forEach(elem =>{
      // console.log(elem.navigation_id , this.currentTab)
      if(`${elem.navigation_id}` === this.currentTab) {
        item = elem
      }
    })
    // console.log(item)
    const path = "/combo-card-shop" + this.getPath(item);
    if (this.$route.path === path) return;
    // console.log(path)
    this.$router.push({path}).then(() => {
      // console.log(path)
      this.currentTab = `${item.navigation_id}`
      this.searchContent = ''
      this.emitParams(1)
    })
  }

  // type=1 页签 type=2 分类 type=3 搜索框
  emitParams(type) {
    let params = {
      category_path: this.category,
      keyword: this.searchContent,
      self_goods: this.$route.params.type ? parseInt(this.$route.params.type) : '',
    };
    if (type === 0) {
      if (params.category_path) {
        type = 2
      } else if (params.keyword) {
        type = 3
      } else {
        type = 1
      }
    }

    if (type === 2) {
      this.searchContent = ''
      params.self_goods = 0
    } else if (type === 3) {
      params.category_path = ''
      params.self_goods = 0
    }
    params[this.key_word] = this.searchContent
    // console.log(111)
    if (this.$route.fullPath.includes(this.tabBaseUrl)) {
      if (type === 2 || type === 3) {
        this.currentTab = '-1'
        this.$router.push({
          path: this.$route.path.split('/').slice(0,3).join('/') + '/goods/0/0',
          query: params as any
        })
      }
      this.$emit('change', params);
    } else {
      if (!params.self_goods && !params.keyword && !params.category_path) return;
      if (!this.navList[0]) return;
      const item = this.navList[0];
      let path = this.getPath(item);
      path = path.split('/').slice(0, 3).join('/') + '/goods/0/0',
      // console.log(path)
      this.$router.push({
        path,
        query: params as any
      });
    }
  }
}
